import React from "react"
import ReactMarkdown from 'react-markdown'
import Skeleton from 'react-loading-skeleton';
import { FaGithubSquare } from "react-icons/fa"
import ImageGallery from 'react-image-gallery';

import { Link } from "gatsby"
// import { useI18next } from 'gatsby-plugin-react-i18next';

import SimpleBar from 'simplebar-react';

import "react-image-gallery/styles/css/image-gallery.css";
import "simplebar/dist/simplebar.min.css";

const Project = ({ description, title, url, github, stack, image, slug }) => {
  // const {t} = useI18next();
  const imagesToGallery = image.map((img, index) => {
    return {
      // renderItem: () => {
      //   return (
      //     <GatsbyImage
      //       key={`${index}_${title}`}
      //       alt={title}
      //       image={getImage(img.localFile)}
      //     />
      //   )
      // },
      // renderItem: () => {
      //   return (
      //     <img
      //       key={`${index}_${title}`}
      //       alt={title}
      //       src={img.localFile.url}
      //     />
      //   )
      // },
      original: img.localFile.publicURL,
      thumbnail: img.localFile.publicURL,
    }
  });
  return (
    <article className="project">
      {/* {
        image.map((img, index) => {
          return (
            <GatsbyImage
              key={`${index}_${title}`}
              alt={title}
              image={getImage(img.localFile)}
              className="project-img"
            />
          )
        })
      } */}

      <ImageGallery
        items={imagesToGallery}
        className="project-img-gallery"
        lazyLoad={true}
        showPlayButton={false}
        showBullets={true}
      />
      <div className="project-info">
        <Link to={`/projects/${slug}`} className="project-slug">
          <h3 style={{textTransform: 'none', marginTop: "0.575rem", marginBottom: "0.575rem"}}>
            <span role="img" aria-label="pin" aria-labelledby="pin">📌</span>
            &nbsp;{title}
          </h3>
        </Link>
        {
          url &&
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
          >
            <h4 style={{marginTop: "0.575rem", marginBottom: "0.575rem"}}>
              <span role="img" aria-label="clip" aria-labelledby="clip">🔗</span>
              <span style={{color: 'var(--clr-primary-3)'}}>{url}&nbsp;</span>
            </h4>
          </a>
        }
        <div style={{textAlign: 'justify', marginBottom: '1rem'}}>
          <SimpleBar style={{ maxHeight: 500}}>
            <div style={{paddingRight: '1rem'}}>
              {
                description ?
                  <ReactMarkdown>{description}</ReactMarkdown> :
                  <Skeleton count={3} />
              }
            </div>
          </SimpleBar>
        </div>
        <div className="project-stack">
          {
            stack.map(item => {
              return <span key={item.id} style={{boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"}}>{item.title}</span>
            })
          }
        </div>
        <div className="project-links" style={{textAlign: 'center'}}>
          {
            github &&
            <a
              href={github}
              target="blank"
              rel="noreferrer"
              style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}
            >
              <span>github repo&nbsp;</span>
              <FaGithubSquare className="project-icon" />
            </a>
          }
        </div>
      </div>
    </article>
  )
}

export default Project