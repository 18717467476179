import React from "react"

// import { graphql } from "gatsby"
import { Link, useI18next } from 'gatsby-plugin-react-i18next';

// import { motion } from "framer-motion"

import Title from "./Title"
import Project from "./Project"

const Projects = ({ title, projects, showLink, locale }) => {
	const {t} = useI18next();

  return (
    <section className="section projects">
			<div className="section-inner">
				<Title title={title} />
				<div className="section-center projects-center">
					{
						projects.map((project, index) => {
              if (project.locale.indexOf(locale) === -1) return '';
              return (
								<Project
									key={project.id}
									index={index}
									{...project}
									locale={locale}
								/>
							)
						})
					}
				</div>
				{
					showLink &&
					<Link to="/projects" className="btn center-btn">
						<span role="img" aria-label="see projects" aria-labelledby="see projects">🚀</span>
						&nbsp;{t("Todos mis proyectos")}
					</Link>}
			</div>
    </section>
  )
}

export default Projects